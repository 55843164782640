var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBanner
    ? _c("alert-banner", { attrs: { id: "alert-banner" } }, [
        _vm._v("\n  You have\n\n  "),
        _c("div", {
          staticClass: "pl-1",
          domProps: { innerHTML: _vm._s(_vm.alertHtml) },
          on: { click: _vm.logOraBannerInteraction },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }