<template>
  <alert-banner v-if="showBanner" id="alert-banner">
    You have

    <div
      class="pl-1"
      v-html="alertHtml"
      @click="logOraBannerInteraction"
    />
  </alert-banner>
</template>

<script>
import { mapGetters } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'
import * as DOMPurify from 'dompurify'

export default {
  name: 'ItemsRequiringAttentionBanner',
  components: {
    AlertBanner: () => import('@/components/shared/AlertBanner.vue'),
  },
  computed: {
    ...mapGetters('session', {
      token: 'getToken',
    }),
    ...mapGetters('dashpanel', [
      'orderItemsRequiringAttentionCount',
      'servicesRequiringAttentionCount',
      'companiesRequiringCtaIds',
    ]),
    ...mapGetters('verifyOrder', [
      'orderRequiringVerification',
    ]),
    ...mapGetters('stageline', [
      'currentPeriodName',
      'currentSlide',
    ]),
    hideIfRoute() {
      const hiddenRoutes = [
        'stageline-required-info',
        'stageline-v2-required-info',
        'stageline-post-payment',
        'verify-order',
      ]

      const hiddenSlideLayoutTypes = [
        'verifyOrder',
      ]

      return !!(hiddenRoutes.includes(this.$route.name)) || hiddenSlideLayoutTypes.includes(this.currentSlide?.layout_type)
    },
    hasItemsRequiringAttention() {
      return this.itemsRequiringAttention.length > 0
        || this.orderRequiringVerification
        || (this.hasCompaniesRequiringCta && !this.inStagelineCtaPhase)
    },
    showBanner() {
      return this.token &&
        this.hasItemsRequiringAttention &&
        !this.hideIfRoute
    },
    itemsRequiringAttention() {
      const items = []

      if (
        this.orderRequiringVerification &&
        this.$route.name !== 'verify-order'
      ) {
        items.push([
          'an Order Requiring Verification',
          '/#/verify-order',
        ])
      }
      if (
        this.orderItemsRequiringAttentionCount > 0 &&
        this.$route.name !== 'orderItemsRequiringAttention'
      ) {
        items.push([
          'Order Items Requiring Attention',
          '/#/order-items-requiring-attention',
        ])
      }
      if (
        this.servicesRequiringAttentionCount > 0 &&
        this.$route.name !== 'servicesRequiringAttention'
      ) {
        items.push([
          'Services Requiring Attention',
          '/#/services-requiring-attention',
        ])
      }
      if (
        this.hasCompaniesRequiringCta &&
        !this.inStagelineCtaPhase
      ) {
        items.push([
          `(${this.companiesRequiringCtaIds.length}) Companies requiring compliance with the Corporate Transparency Act`,
          `/#/stageline-v2/${this.companiesRequiringCtaIds[0]}/start`,
        ])
      }

      return items
    },
    hasCompaniesRequiringCta() {
      return this.companiesRequiringCtaIds?.length > 0
    },
    inStagelineCtaPhase() {
      return this.$route.name === 'stageline-v2-start' && this.currentPeriodName === 'corporate_transparency_act'
    },
    alertHtml() {
      switch (this.itemsRequiringAttention.length) {
        case 1: return DOMPurify.sanitize(this.htmlify(this.itemsRequiringAttention[0])) + '.'
        default: {
          const itemsRequiringAttention_ = this.itemsRequiringAttention
          const lastItem = itemsRequiringAttention_.pop()
          const items = itemsRequiringAttention_.map(itemRequiringAttention => {
            return DOMPurify.sanitize(this.htmlify(itemRequiringAttention))
          })
          const last = ` and ${this.htmlify(lastItem)}.`
          return items.join(', ') + last
        }
      }
    },
  },
  methods: {
    htmlify(itemRequiringAttention) {
      const [text,path] = itemRequiringAttention

      return `<a href="${path}">${text}</a>`
    },
    async logOraBannerInteraction() {
      await createOrFindClientInteractionLog({
        category: 'page-interaction',
        subCategory: 'order-requiring-attention-page',
        interaction: {
          type: 'button',
          action: 'redirect',
          name: 'order-requiring-attention-banner',
        },
        incompleteLogFromToday: true,
      })
    },
  },
}

</script>
<style lang="scss">
@media print {
  #alert-banner {
    display: none;

    div {
      display: none
    }
  }
}
</style>
